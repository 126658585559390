import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { StepWrapper } from 'views/estimate-project/components/step-wrapper';
import { MAX_ESTIMATION_FILE_SIZE } from 'views/estimate-project/constants';
import { Checkbox } from 'components/checkbox-new';
import { DropFileUploader } from 'components/drop-file-uplaoder';
import { Input } from 'components/input-new';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { PATHS } from 'constants/paths';

import { ADDITIONAL_INFO_STEP_FIELDS } from './_constants';

const SCheckboxSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    gap: 0.5rem;
`;

const SInputWrapper = styled.div`
    label {
        font-size: 1rem;
        color: var(--text-black-color);
        margin-bottom: 0.75rem;
    }
`;

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

const SDisclaimer = styled.p`
    font-size: 0.875rem;
    color: var(--gray-color-80);
    line-height: 1.4em;
`;

const ReadMoreLessButton = styled.span`
    all: unset;
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: underline;
    display: inline;
    word-wrap: break-word;
`;

export const AdditionalInfoStep = () => {
    const { formatMessage } = useIntl();
    const { control, formState } = useFormContext();
    const [isDisclaimerExpanded, setIsDisclaimerExpanded] = useState(false);

    const handleOpenDisclaimer = () => {
        setIsDisclaimerExpanded(true);
    };

    return (
        <StepWrapper
            title={formatMessage({
                id: 'estimate-the-project.additional-info-step.title',
            })}
            description={formatMessage({
                id: 'estimate-the-project.additional-info-step.description',
            })}
        >
            <SInputWrapper>
                <Input
                    name={ADDITIONAL_INFO_STEP_FIELDS.ADDITIONAL_DETAILS}
                    control={control}
                    label={formatMessage({
                        id: 'estimate-the-project.additional-info-step.additional-details.label',
                    })}
                    placeholder={formatMessage({
                        id: 'estimate-the-project.additional-info-step.additional-details.placeholder',
                    })}
                    as="textarea"
                    rows={8}
                    error={
                        formState.errors[
                            ADDITIONAL_INFO_STEP_FIELDS.ADDITIONAL_DETAILS
                        ]
                    }
                />
            </SInputWrapper>

            <DropFileUploader
                title={formatMessage({
                    id: 'estimate-the-project.additional-info-step.upload.title',
                })}
                description={formatMessage({
                    id: 'estimate-the-project.additional-info-step.upload.description',
                })}
                name={ADDITIONAL_INFO_STEP_FIELDS.ATTACHMENTS}
                control={control}
                error={
                    formState.errors[ADDITIONAL_INFO_STEP_FIELDS.ATTACHMENTS]
                }
                allowedMaxFilesCount="unlimited"
                allowedFileSize={MAX_ESTIMATION_FILE_SIZE}
            />

            <SCheckboxSection>
                <Checkbox
                    name={ADDITIONAL_INFO_STEP_FIELDS.PRIVACY_POLICY}
                    control={control}
                    label={formatMessage(
                        {
                            id: 'general.privacyPolicyTerms',
                        },
                        {
                            link: (chunks) => (
                                <SPrefixLangLink to={PATHS.PRIVACY_POLICY}>
                                    {chunks}
                                </SPrefixLangLink>
                            ),
                        },
                    )}
                    error={
                        formState.errors[
                            ADDITIONAL_INFO_STEP_FIELDS.PRIVACY_POLICY
                        ]
                    }
                    isRequired
                />
                <Checkbox
                    name={ADDITIONAL_INFO_STEP_FIELDS.NDA}
                    control={control}
                    label={formatMessage({
                        id: 'estimate-the-project.additional-info-step.sign-nda',
                    })}
                />
            </SCheckboxSection>
            <SDisclaimer>
                <FormattedMessage id="estimate-the-project.additional-info-step.disclaimer" />
                {isDisclaimerExpanded ? (
                    <FormattedMessage id="estimate-the-project.additional-info-step.disclaimer-expansion" />
                ) : (
                    <ReadMoreLessButton onClick={handleOpenDisclaimer}>
                        <strong>
                            <FormattedMessage id="estimate-the-project.additional-info-step.read-more" />
                        </strong>
                    </ReadMoreLessButton>
                )}
            </SDisclaimer>
        </StepWrapper>
    );
};
